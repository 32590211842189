<script>
import { getCurrentInstance } from 'vue';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useCkeditorStore } from '@components/Ckeditor/stores/ckeditor-store.js';
import { mapWritableState, mapActions } from 'pinia';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'
import { SuperdocService } from '@/services/superdoc-services';
import { getFileObject, DOCX } from '@harbour-enterprises/superdoc';

export default {
  name: 'ViewAgreementRenderer',
  setup() {
    return {
      dashboardStore: useDashboardStore(),
      ckeditorStore: useCkeditorStore(),
      instance: getCurrentInstance(),
    };
  },
  data() {
    return {
      linkObject: null,
      isCkDoc: false,
    };
  },
  created() {
    this.linkObject = useLinkObject(this.params.data);
    this.isCkDoc = this.linkObject.ckeditorAgreementId && !this.isLinkCompleted(this.linkObject);
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  computed: {
    ...mapWritableState(useDashboardStore, ['previewURL', 'isPreviewModalActive']),
    isSuperdoc() {
      return !!this.linkObject.superdocId && !this.isLinkCompleted(this.linkObject);
    },
  },
  methods: {
    ...mapActions(useDashboardStore, ['openLinkBasedPdfPreview', 'openCkDocPreview', 'isLinkCompleted']),

    async openSuperdocPreview() {
      const files = await SuperdocService.getFilesForAgreement(
        this.linkObject.agreementId,
        this.linkObject.id
      );
      const fileObject = await getFileObject(files[0].url, 'preview.docx', DOCX);
      const previewOptions = {
        id: this.linkObject.id,
        linktitle: this.linkObject.linktitle,
        source: fileObject,
        editingDisabled: this.dashboardStore.linkHasEditingDisabled(this.params),
        props: {
          superdocId: this.linkObject.superdocId,
          linkObject: this.linkObject,
          folderId: this.linkObject.folderId,
          templateId: this.linkObject.templateId,
          linkDisplayId: this.linkObject.id,
          linkDisplayIdToEdit: this.linkObject.id,
          isEditingApprovalFlowLink: true,
        },
      };
      this.openModal(previewOptions);
      this.dashboardStore.setLoadingView(false);
    },

    async openPreview() {
      const {
        id,
        linktitle,
        agreementId
      } = this.linkObject;

      this.dashboardStore.setLoadingView(true);

      const isCompleted = this.isLinkCompleted(this.linkObject);
      const mode = this.linkObject.authMethod?.authmode;
      let source = null;

      if (this.linkObject.superdocId && !isCompleted) {
        return this.openSuperdocPreview();
      }

      if (this.isCkDoc) {
        source = await this.ckeditorStore.getCkeditorTemplateHtml(agreementId, id)
      } else {

        let pdfUrl = `/agreepdfunsigned/${id}`;

        if (isCompleted) {
          const linkAssets = this.linkObject?.submissions;
          if (linkAssets && linkAssets.length) {
            const linkAssetsFirstAsset = linkAssets[0];
            if (linkAssetsFirstAsset) pdfUrl = `/agreepdf/${linkAssetsFirstAsset.id}/agreelink/${id}`;
          }
        }

        const sourceResp = await axios.get(pdfUrl, {responseType: 'arraybuffer'})
        source = new File([sourceResp.data], `${linktitle}.pdf`, {type: 'application/pdf'});
      }
      this.dashboardStore.setLoadingView(false);

      // check if editing enabled
      const editingDisabled = this.dashboardStore.linkHasPendingApproval(this.params) || (mode && isCompleted);

      this.openModal({ linktitle, id, source, editingDisabled });
    },

    openModal({
      linktitle,
      id,
      source,
      editingDisabled = false,
      props = {},
    }) {
      const modal = this.$openSuperdocViewerModal({
        linktitle,
        id,
        source,
        headerCtas: [
          {
            id: 'download',
            label: 'Download',
            icon: 'file-arrow-down',
            type: 'secondary',
          },
          {
            id: 'edit',
            label: 'Edit',
            icon: 'pen',
            type: 'primary',
            disabled: editingDisabled,
            onClick: () => {
              modal.close();
              this.dashboardStore.previewLink = this.linkObject;
              this.dashboardStore.pdfPreviewEditClick(this.instance, props)
            }
          }]
      });
    }
  },
};
</script>

<template>
  <div class="file-display-column">
    <button class="hrbr-grid-button" @click.stop="openPreview">
      <i v-if="isCkDoc || isSuperdoc" class="fal fa-thin fa-file-word ck-doc"></i>
      <i v-else class="fal fa-file-pdf pdf-doc"></i>
      <span class="view-text">View</span>
    </button>
  </div>
</template>

<style scoped>
.superdoc-viewer-modal {
  display: flex;
  justify-content: center;
}

.view-text {
  margin-left: 5px;
}

.file-display-column {
  color: #6a6a6a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container {
  font-size: 23px;
  display: inline-block;
  padding: 1px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.ck-doc {
  font-weight: 300 !important;
  transition: all 0.08s ease-in-out;
}

.pdf-doc {
  font-weight: 300 !important;
  transition: all 0.08s ease-in-out;
}

.icon-container:hover {
  background-color: #e2e2e2;
  color: #333;
}

.file-display-column i {}

.file-type-pill {
  display: inline-block;
  line-height: 1em;
  background-color: #f7f7f7;
  border-radius: 10px;
  font-size: 8px;
  padding: 3px 8px;
  margin: 5px 0 0 0;
}
</style>
